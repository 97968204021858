import {  useState } from "react";
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Item  from '@mui/material/Grid';
import ModalTeplo from './ModalTeplo';
import MessageGoTeplo from "./MessageGoTeplo";
import Button from '@mui/material/Button';
import Examples from "./Examples"
import Description from './Description';
import TelegramIcon from '@mui/icons-material/Telegram';
import IconButton from '@mui/material/IconButton';

export default function Footer(){
    const [modalActiveTeplo,setModalActiveTeplo] = useState(false)
    return(
        <div className="Footer">
            <div className="center">
            <Button variant="contained" href="#StartScreen"><h22>↑Вернуться к началу сайта↑</h22> </Button>
            <br/>  <br/> <h10><a href="https://mail.yandex.ru/compose?mailto=dmitrov-otoplenie@yandex.ru">dmitrov-otoplenie@ya.ru</a></h10>
            <br/><h10><a href="tel:+74956422431">+7(495)642-24-31</a></h10>   
            <br/><h10><a href="'https://yandex.ru/maps/-/CCU6VISgKB">'Наш Адрес</a></h10>  
            <br/><h10><a href="https://wa.me/79645545829?text=Здравствуйте, я сайта dmitrov-otoplenie.ru интересуют ваши услуги">WhatsApp</a></h10>               
            </div>

        </div>

    )
}
