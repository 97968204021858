import * as React from 'react';
import Grid from '@mui/material/Grid';
import Item  from '@mui/material/Grid';
import ModalTeplo from './ModalTeplo';
import MessageGoTeplo from "./MessageGoTeplo";
import Button from '@mui/material/Button';
import {  useState } from "react";
import Examples from "./Examples"
import Description from './Description';
import TelegramIcon from '@mui/icons-material/Telegram';
import IconButton from '@mui/material/IconButton';

export default function StartScreen(){
    const [modalActiveTeplo,setModalActiveTeplo] = useState(false)
    return(
            <div  id="StartScreen">
            <div className='Purum'>
            {/* <img src={logo} alt='logotip' className="logo" /> */}
                <div  className='Window1'>
                        <h1>ОТОПЛЕНИЕ И ВОДОСНАБЖЕНИЕ</h1>
                </div>
                </div>
                <div className='knopki'  sx={{ justifyContent: 'center'}}>
                <div className='Knopka'><Button href="https://t.me/Dmitrov_Otoplenie_bot" target="_blank" variant="contained" size="large" className='open-btn' sx={{borderRadius:15,paddingX:1,padding:1.5}}><TelegramIcon sx={{width:100,height:100}}/></Button></div>
                <div className='Knopka'><Button sx={{marginBottom:7}} variant="contained" size="large" className='open-btn'onClick={() => setModalActiveTeplo(true)}><h2>Заказать звонок</h2></Button></div>
                </div>
        <ModalTeplo className="modalTeplo" active={modalActiveTeplo} setActive={setModalActiveTeplo}><MessageGoTeplo/></ModalTeplo>  
        </div>
    )
}