import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Kluch from "../img/Kluch.png"
import {  useState } from "react";
import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';
import ModalUslugi1 from './ModalUslugi1';
import ModalUslugi2 from './ModalUslugi2';
import ModalUslugi3 from './ModalUslugi3';
import ModalUslugi4 from './ModalUslugi4';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Uslugi(){
  const [modalActiveUslugi1,setModalActiveUslugi1] = useState(false)
  const [modalActiveUslugi2,setModalActiveUslugi2] = useState(false)
  const [modalActiveUslugi3,setModalActiveUslugi3] = useState(false)
  const [modalActiveUslugi4,setModalActiveUslugi4] = useState(false)

    return (
      <>

      <ModalUslugi1  className="modalExample" active={modalActiveUslugi1} setActive={setModalActiveUslugi1}>
      <h1>Услуги монтажа</h1><br/>
      <h2>Наши специалисты помогут рассчитать, подобрать оборудование и грамотно осуществить монтаж инженерных систем:</h2><br/>
      <h2>• Радиаторное отопление </h2>
      <h2>• Системы тёплых полов </h2>
      <h2>• Горячее и холодное водоснабжение </h2>
      <h2>• Системы водоотведения</h2>
      <h2>• Система очистки воды </h2>
      <h2>• Установка котла и бойлера </h2>
      <h2>• Трубопроводная арматура </h2>
      <h2>• Пуско-наладочные работы </h2>
      <h2>• Подготовка систем в эксплуатации </h2><br/>

      </ModalUslugi1>
      <ModalUslugi2  className="modalExample" active={modalActiveUslugi2} setActive={setModalActiveUslugi2}>
      <h1>Сервис систем отопления</h1><br/>
      <h2>Наши специалисты помогут осуществить обслуживание имеющегося оборудования:</h2><br/>
      <h2>• Газового\Электрического котла </h2>
      <h2>• Бойлера косвенного нагрева </h2>
      <h2>• Систем безопасности котельной</h2>
      <h2>• Замена теплоносителя</h2>
      <h2>• Элементов систем отопления(Радиаторов, зап Арматура, соединения и фиттинги) </h2>
      <h2>• Проверка работы систем перед отопительным сезоном</h2><br/>
      <h2>Чтобы узнать подробности и стоимости услуг нажмите на кнопку заказать звонок </h2>
      </ModalUslugi2>
      <ModalUslugi3  className="modalExample" active={modalActiveUslugi3} setActive={setModalActiveUslugi3}>
      <h1>Скорая помощь котлу</h1><br/>
      <h2>На случай аварийных ситуаций наши специалисты оперативно помогут решить проблему и восстановить работоспособность:</h2><br/>
      <h2>• Систем отопления</h2>
      <h2>• Газового\Электрического котла </h2>
      <h2>• Работу нагрева санитарной воды</h2>
      <h2>В наличии имеется широкий ассортимент всех необходимых запчастей на случай срочного ремонта газового оборудования.</h2>
      <h2>Сообщите нам о проблеме и в кратчайшие сроки специалист решит вашу проблему.</h2>
      </ModalUslugi3>
      <ModalUslugi4  className="modalExample" active={modalActiveUslugi4} setActive={setModalActiveUslugi4}>
      <h1>ЭЛЕКТРИКА и АВТОМАТИЗАЦИЯ</h1><br/>
      <h2>Наши специалисты помогут рассчитать и предложить оптимальную систему автоматизации для вашего дома, состоящей из:</h2><br/>
      <h2>• Автоматика котельной</h2>
      <h2>• Зональное регулирование температуры в помещениях</h2>
      <h2>• Удалённый контроль отопления</h2>
      <h2>• Электрофикация котельной</h2>
      <h2>• Дополнительные системы мониторинга для вашего дома</h2>
      </ModalUslugi4>
        <Box sx={{ width: '100%' }}>
        <div className='center'><h1>Виды наших услуг</h1></div>

        <Grid container sx={{ justifyContent: 'center'}}  spacing={{ xs: 2,sm:5,md: 10 }} columns={{ xs: 100, sm: 100, md: 100 }}  > 
          <Grid item xs={85} sm={45} md={35}>
            <Button className='UslugiBox button1' variant="contained" onClick={() => setModalActiveUslugi1(true)}><div className='innerBox'><h22>Монтаж</h22><br/><h23> Отопление Водоотчистка Водоснабжение  Котельная</h23></div></Button>
          </Grid>
          <Grid item xs={85} sm={45} md={35}>
            <Button className='UslugiBox button2' variant="contained" onClick={() => setModalActiveUslugi2(true)}><div className='innerBox'><h22>Сервис</h22><br/><h23>Систем отопления</h23> </div></Button>
          </Grid>
          <Grid item xs={85} sm={45} md={35}>
            <Button className='UslugiBox button3' variant="contained" onClick={() => setModalActiveUslugi3(true)}><div className='innerBox'><h22>Скорая помощь котлу</h22></div></Button>
          </Grid>
          <Grid item xs={85} sm={45} md={35}>
            <Button className='UslugiBox button4' variant="contained" onClick={() => setModalActiveUslugi4(true)}><div className='innerBox'><h22>Электрика и Автоматизация</h22> </div></Button>
          </Grid>
        </Grid>
      </Box>
      </>
     );
}